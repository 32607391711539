import React from "react"
import { Formik, Form, FastField, ErrorMessage } from "formik"
import Recaptcha from "react-google-recaptcha"
import * as Yup from "yup"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        recaptcha: "",
        success: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Full name field is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email field is required"),
        message: Yup.string().required("Message field is required"),
        recaptcha:
          process.env.NODE_ENV !== "development"
            ? Yup.string().required("Please verify!")
            : Yup.string(),
      })}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...values }),
        })
          .then(() => {
            actions.setSubmitting(false)
            actions.setFieldValue("success", true)
            setTimeout(() => actions.resetForm(), 6000)
          })
          .catch(() => {
            actions.setSubmitting(false)
            actions.setFieldValue("success", false)
            alert("Something went wrong, please try again!")
          })
      }}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting }) => (
        <Form
          action="POST"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div style={{ position: "relative", marginBottom: "1rem" }}>
            <FastField
              className={
                touched.name && errors.name
                  ? "contact-input-error"
                  : "contact-input"
              }
              type="text"
              name="name"
              component="input"
              aria-label="name"
              placeholder="Full name*"
              id="name"
              error={touched.name && errors.name}
            />
            <ErrorMessage
              name="name"
              style={{ color: "#ff4136" }}
              component="span"
            />
          </div>
          <div style={{ position: "relative", marginBottom: "1rem" }}>
            <FastField
              className={
                touched.name && errors.name
                  ? "contact-input-error"
                  : "contact-input"
              }
              id="email"
              aria-label="email"
              component="input"
              type="email"
              name="email"
              placeholder="Email*"
              error={touched.email && errors.email}
            />
            <ErrorMessage
              name="email"
              style={{ color: "#ff4136" }}
              component="span"
            />
          </div>
          <div style={{ position: "relative", marginBottom: "1rem" }}>
            <FastField
              className={
                touched.name && errors.name
                  ? "contact-input-error"
                  : "contact-input"
              }
              component="textarea"
              aria-label="message"
              id="message"
              rows="8"
              type="text"
              name="message"
              placeholder="Message*"
              error={touched.message && errors.message}
            />
            <ErrorMessage
              name="message"
              style={{ color: "#ff4136" }}
              component="span"
            />
          </div>
          {values.name &&
            values.email &&
            values.message &&
            process.env.NODE_ENV !== "development" && (
              <div style={{ position: "relative", marginBottom: "1rem" }}>
                <FastField
                  component={Recaptcha}
                  sitekey={process.env.GATSBY_PORTFOLIO_RECAPTCHA_KEY}
                  name="recaptcha"
                  onChange={value => setFieldValue("recaptcha", value)}
                />
                <ErrorMessage
                  style={{ color: "#ff4136" }}
                  component="span"
                  name="recaptcha"
                />
              </div>
            )}

          {values.success && (
            <div style={{ position: "relative", marginBottom: "1rem" }}>
              <div style={{ textAlign: "left", fontWeight: "normal" }}>
                <h5>Thanks, your message has been successfully sent.</h5>
              </div>
            </div>
          )}

          <button
            className="contact-button"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  )
}
export default ContactForm
