import React from "react"
import Layout from "../components/layout"
import { Card, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ContactForm from "../components/ContactForm"
const contact = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact - Pamela Mary Brown</title>
      </Helmet>
      <h2>Creative Writing Consultant</h2>

      <Card.Text style={{ marginBottom: "7px" }}>Available for: </Card.Text>
      <Card.Text>
        Poetry Readings <br />
        Creative Writing Workshops <br />
        Talks and Seminars <br />
        Editorial Advice <br />
        Project Planning and Design <br />
        Creative Reviews <br />
        Book Layout, Formatting and Industry Standards <br/>
        OCN Accreditation Delivery 
      </Card.Text>
      <Card
        style={{ border: "none", backgroundColor: "transparent" }}
        className="contact-card text-right"
      >
        <Row>
          <Col>
            <ContactForm />
          </Col>
        </Row>
      </Card>
    </Layout>
  )
}

export default contact
